import React from "react";
import Section from "../../../HOC/Section";
import Image from "../../../assets/images/Solar/DJI_0689.JPG";
const RSBody = (props) => {
  return (
    <Section color="grey" size="lg" shape="tilt" left={true} add={true}>
      <div className="row align-items-center justify-content-center">
        <div className="col-12 col-md-7">
          <h2 className="title mb-3">
            EMPOWERING OUR
            <br />
            <span>CUSTOMERS</span>
          </h2>
          <p>
            At Steadfast Solar we pride ourselves on offering a tailored
            Solution to all of our Customers, unlike most Solar Companies within
            the Industry, who are focused solely on selling another Solar
            System. We come and meet with all our Clients and discuss the Right
            Solar System for them, sitting down, listening to them, and then
            arranging a Solar System which will meet their needs and beyond All
            of Steadfast Solar products come with an Australian Warranty, as it
            uses only Components which have the manufacturers located in
            Australia, ensuring that the best after sales service and support is
            available to all of its Customers, and also ensuring that the
            Warranty offered, can be delivered on if needed. If you’re looking
            for a quality solar system from a trusted local business with a
            proven track record in delivering on its promises, then you can’t
            look past Steadfast Solar.
          </p>
        </div>
        <div className="col-12 col-md-5">
          <div className="border-image img-border-invert img-shadow">
            <img src={Image} alt={Image} className="img-fluid" />
          </div>
        </div>
      </div>
    </Section>
  );
};

export default RSBody;
