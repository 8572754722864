import React, { useEffect } from "react";
import WebFont from "webfontloader";

import "bootstrap/dist/css/bootstrap.css";
import "normalize.css/normalize.css";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import {
  applyMiddleware,
  combineReducers,
  legacy_createStore as createStore,
} from "redux";

import { createLogger } from "redux-logger";
import { thunk } from "redux-thunk";
import "./assets/css/App.css";
import "./assets/css/Loading.css";
import "./assets/css/index.css";
import "./assets/css/lineicons.css";
import "./assets/css/shapes.css";
import App from "./contents/App";
import { BaseReducer } from "./reducer/reducer";
import reportWebVitals from "./reportWebVitals";

const logger = createLogger();
const rootReducer = combineReducers({ BaseReducer });
const store = createStore(rootReducer, applyMiddleware(thunk, logger));

const Load = () => {
  useEffect(() => {
    WebFont.load({
      google: {
        families: [
          "Inter:300,400,500,600,700,800,900",
          "Montserrat:300,400,600,700,800,900",
          "Poppins:100,200,300,400,500,600,700",
        ],
      },
    });
  }, []);
  return (
    <React.StrictMode>
      <Router>
        <Provider store={store}>
          <App />
        </Provider>
      </Router>
    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Load />);

reportWebVitals();
