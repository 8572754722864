import Plyr from "plyr-react";
import "plyr-react/plyr.css";
import React from "react";
// import Start from '../../assets/images/play_sprite.svg';

const VideoPlayer = (props) => {
  const plyrProps = {
    source: {
      type: "video",
      title: props.title,
      sources: [
        {
          src: props.src,
          type: "video/webm",
          size: 1080,
        },
      ],
      poster: props.thumb,
    }, // https://github.com/sampotts/plyr#the-source-setter
    options: {
      loop: { active: false },
      resetOnEnd: true,
      quality: { default: 1080 },
      displayDuration: false,
      settings: ["captions", "quality", "loop"],
      controls: ["play-large", "volume", "settings", "pip", "fullscreen"],
    }, // https://github.com/sampotts/plyr#options
    // Direct props for inner video tag (mdn.io/video)
  };
  return (
    <div>
      <Plyr {...plyrProps} />
    </div>
  );
};

export default VideoPlayer;
