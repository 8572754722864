import React, { useEffect, useState } from "react";
import { animateScroll as scroller } from "react-scroll";
import B2t from "../../Components/B2t/B2t";
import ContactForm from "../../Components/ContactForm/ContactForm";
import Intro from "../../Components/Intro/Intro";
import Products from "../../Components/Products/Products";
import ColorBlocks from "../../HOC/ColorBlocks";
import Icons from "../../HOC/Icons";
import Section from "../../HOC/Section";

import I1 from "../../assets/images/Solar/1G2A8944.jpg";
import I2 from "../../assets/images/Solar/DJI_0737.JPG";

const Contact = (props) => {
  const [firstLoad, setFirstLoad] = useState(false);
  useEffect(() => {
    if (!firstLoad) {
      scroller.scrollTo(0, {
        duration: 200,
        delay: 0,
        smooth: "easeInOutQuart",
      });
      setFirstLoad(true);
    }
  }, [firstLoad]);
  return (
    <div style={{ overflow: "hidden" }}>
      <B2t show={props.show} />
      <Intro title="Contact Us" />
      <Section size="sm" shape="normal" foot={false} white={true} color="grey">
        <div className="row d-flex col-blocks redGreen pt-0 px-0 px-md-3 justify-content center">
          <ColorBlocks title="Professionals">
            Our solar business boasts a team of highly skilled and trained
            professionals who bring extensive knowledge and expertise to every
            sales interaction.
          </ColorBlocks>
          <ColorBlocks title="Experts">
            When you choose our solar business, you're partnering with industry
            experts who stay updated on the latest advancements, providing you
            with accurate information and tailored solutions for your specific
            needs.
          </ColorBlocks>
          <ColorBlocks title="Reliable">
            We understand the importance of reliability in the solar industry.
            Our products and services are consistently dependable, ensuring
            customer satisfaction and peace of mind.
          </ColorBlocks>
        </div>
        <div className="row align-items-center justift-content-center">
          <div className="col-12 mb-60">
            <ContactForm
              mt={0}
              onMailSend={props.onMailSend}
              onMailSendError={props.onMailSendError}
            />
          </div>
        </div>
      </Section>
      <Section
        size="lg"
        shape="normal"
        foot={false}
        white={false}
        color="white"
      >
        <div className="row align-items-center justift-content-center mb-80">
          <div className="col-12 col-md-5 mb-60">
            <div className="border-image img-border img-shadow">
              <img src={I1} alt={I1} className="img-fluid" />
            </div>
          </div>
          <div className="col-12 col-md-5 offset-lg-2  mb-20">
            <div className="section-title text-center mb-20">
              <h3>
                Contact <span>Steadfast Solar</span> Office
              </h3>
            </div>
            <Icons text="08 8257 9199" icon="briefcase" />
            <Icons
              text="272 Sir Donald Bradman Drive Cowandilla SA 5033"
              icon="buildings"
            />
            <Icons text="info@steadfastsolar.com.au" icon="envelope" />
          </div>
        </div>
        <div className="row align-items-center justift-content-center mb-60">
          <div className="col-12 col-md-5">
            <div className="section-title text-center mb-20">
              <h3>
                Contact <span>Peter</span> Directly
              </h3>
            </div>
            <Icons text="0477 711 321" icon="mobile" right={false} />
            <Icons
              text="peter@steadfastsolar.com.au"
              icon="envelope"
              right={false}
            />
          </div>
          <div className="col-12 col-md-5 mb-60 offset-lg-2">
            <div className="border-image img-border img-shadow">
              <img src={I2} alt={I2} className="img-fluid" />
            </div>
          </div>
        </div>
      </Section>
      <Products color="grey" heading={true} title="Our " span="Products" />
    </div>
  );
};

export default Contact;
