import React from "react";
import Section from "../../../HOC/Section";
import Image from "../../../assets/images/Solar/DJI_0695.JPG";
import Aus from "../../../assets/images/aus.jpg";
const SPBody = (props) => {
  return (
    <Section color="grey" size="lg" shape="tilt" left={true} add={true}>
      <div className="row align-items-center justify-content-center">
        <div className="col-12 col-md-7 mb-30">
          <h2 className="title mb-30">
            6 Easy Steps As To How The Accredited
            <br />
            <span>Solar Partner</span> Program Works
          </h2>
          <p>
            We run through with you the basics of Solar – enough to be able to
            get someone excited about the benefits of having a Solar system You
            get the ball rolling with a prospective customer about solar and
            advise them that an experienced and qualified Steadfast Solar
            representative will contact them and meet with them to discuss the
            solar further
          </p>
          <p>
            Marketing material and information about Steadfast Solar will be
            made available to you to pass on to your prospective customer.
          </p>
        </div>
        <div className="col-12 col-md-5 mb-70">
          <div className="border-image img-border-invert img-shadow">
            <img src={Image} alt={Image} className="img-fluid" />
          </div>
        </div>
        <div className="col-12 col-md-5 mb-70">
          <div className="border-image img-border img-shadow">
            <img src={Aus} alt={Aus} className="img-fluid" />
          </div>
        </div>
        <div className="col-12 col-md-7">
          <p>
            You pass on their details to us, and we’ll re-engage with the
            prospective customer, meet with them, and arrange a solar package to
            meet their needs We’ll keep you informed of the progress Upon
            installation of the Solar System you will be paid the commission fee
          </p>
          <p>
            The Steadfast Solar Accredited Solar Partner Program offers an easy
            way to generate additional revenue for your business. Making that
            additional money has never been easier!
          </p>
        </div>
      </div>
    </Section>
  );
};

export default SPBody;
