import React, { useEffect } from "react";
import { animateScroll as scroller } from "react-scroll";
import Style from '../../Styles/StyleFooter'

import Thumb from "../../assets/images/access_denied.png";
import "./error.css";

const Error = (props) => {
  useEffect(() => {
    scroller.scrollTo(0, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  }, [props]);

  return (
    <div className="section section-sm denied">
      <div className="container grid-xl">
        <div className="columns align-items-center justify-content-center">
          <div className="column col-md-12 col-8">
            <h1>
              ACCESS, <span>DENIED!</span>
            </h1>
            <h4>
              <span>Horizon Christian School</span> has been notified of this
              incident
            </h4>
            <h5>
              Access to these results from unapproved sources are prohibited!
              <br />
              These results are private and to be only viewed and discussed with
              approved Staff/Parents/guardians.
            </h5>
            <br />
            <p>
              Due to the sensitivity of the information you wish to access,{" "}
              <span>Horizon Christian School</span> require these URL's to be
              encrypted and contain an encrypted OTP to be verified.
              <br />
              In this case we log all access/attempted access to these
              resources.
            </p>
            <p className="mb-1">
              <b>Including and not limited to:</b>
            </p>
            <ul>
              <li>IP Addresses</li>
              <li>Geographical Information</li>
              <li>ISP Data</li>
              <li>Tracking History to this page</li>
            </ul>
          </div>
          <div className="column hide-md col-4">
            <img src={Thumb} alt={Thumb} className="img-responsive" />
          </div>
        </div>
      </div>
      <Style />
    </div>
  );
};

export default Error;
