import AOS from "aos";
import "aos/dist/aos.css";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { animateScroll as scroller } from "react-scroll";
import Error from "../Components/Error/Error";
import Layout from "../HOC/Layout";
import Loading from "../HOC/Loading";
import {
  setB2T,
  setMailSend,
  setMailSendError,
  setNavbar,
  setToggle,
} from "../reducer/action";
// import About from "./About/About";
import About from "./About/About";
import Builders from "./Builders/Builders";
import Commercial from "./Commercial/Commercial";
import Contact from "./Contact/Contact";
import Main from "./Main/Main";
import Resedential from "./Resedential/Resedential";
import SolarPartner from "./SolarPartner/SolarPartner";

const mapStateToProps = (state) => {
  return {
    navbar: state.BaseReducer.navbar,
    toggle: state.BaseReducer.toggle,
    show: state.BaseReducer.show,
    sent: state.BaseReducer.sent,
    sentError: state.BaseReducer.sentError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetNavbar: (link) => dispatch(setNavbar(link)),
    onSetToggle: (link) => dispatch(setToggle(link)),
    onShowB2T: (link) => dispatch(setB2T(link)),
    onMailSend: (link) => dispatch(setMailSend(link)),
    onMailSendError: (link) => dispatch(setMailSendError(link)),
  };
};

class App extends Component {
  componentDidMount() {
    scroller.scrollTo(0, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
    window.addEventListener("scroll", this.Scroller, { passive: true });
    AOS.init({
      duration: 900,
      easing: "ease-in-out-back",
    });
  }

  Scroller = () => {
    if (window.scrollY <= 30 && window.scrollY >= 0) {
      console.log("Number 1 : " + window.scrollY);
      this.props.onSetNavbar(undefined);
      this.props.onShowB2T(false);
    } else if (
      (window.scrollY >= 31 && window.scrollY <= 60) ||
      (window.scrollY >= 31 && this.props.navbar === undefined)
    ) {
      console.log("Number 2 : " + window.scrollY);
      this.props.onSetNavbar("shrink");
      this.props.onShowB2T(true);
    }
  };

  render() {
    return (
      <Routes>
        <Route
          path="/"
          exact
          element={
            <Layout
              navbar={this.props.navbar}
              onSetToggle={this.props.onSetToggle}
              toggle={this.props.toggle}
            >
              <Main
                show={this.props.show}
                onMailSend={this.props.onMailSend}
                sent={this.props.sent}
                onMailSendError={this.props.onMailSendError}
                sentError={this.props.sentError}
              />
            </Layout>
          }
        />
        <Route
          path="/about"
          element={
            <Layout
              navbar={this.props.navbar}
              onSetToggle={this.props.onSetToggle}
              toggle={this.props.toggle}
            >
              <About show={this.props.show} />
            </Layout>
          }
        />
        <Route
          path="/contactus"
          element={
            <Layout
              navbar={this.props.navbar}
              onSetToggle={this.props.onSetToggle}
              toggle={this.props.toggle}
            >
              <Contact
                show={this.props.show}
                onMailSend={this.props.onMailSend}
                sent={this.props.sent}
                onMailSendError={this.props.onMailSendError}
                sentError={this.props.sentError}
              />
            </Layout>
          }
        />
        <Route
          path="/residential-solar"
          element={
            <Layout
              navbar={this.props.navbar}
              onSetToggle={this.props.onSetToggle}
              toggle={this.props.toggle}
            >
              <Resedential show={this.props.show} />
            </Layout>
          }
        />
        <Route
          path="/commercial-solar"
          element={
            <Layout
              navbar={this.props.navbar}
              onSetToggle={this.props.onSetToggle}
              toggle={this.props.toggle}
            >
              <Commercial show={this.props.show} />
            </Layout>
          }
        />
        <Route
          path="/builders"
          element={
            <Layout
              navbar={this.props.navbar}
              onSetToggle={this.props.onSetToggle}
              toggle={this.props.toggle}
            >
              <Builders show={this.props.show} />
            </Layout>
          }
        />
        <Route
          path="/accredited-solar-partners"
          element={
            <Layout
              navbar={this.props.navbar}
              onSetToggle={this.props.onSetToggle}
              toggle={this.props.toggle}
            >
              <SolarPartner show={this.props.show} />
            </Layout>
          }
        />
        <Route
          path="/denied"
          element={
            <div>
              {this.props.loader ? (
                <Loading />
              ) : (
                <Layout>
                  <Error
                    runOnce={this.props.runOnce}
                    onSetRunOnce={this.props.onSetRunOnce}
                  />
                </Layout>
              )}
            </div>
          }
        />
        <Route path="*" element={<Navigate replace to="/denied" />} />
      </Routes>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
